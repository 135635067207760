import moment from "moment";
import {
  Schedule,
  sortArray,
  Writeable,
} from "../../../data/api/types/schedule";
import { ScheduleVehicle } from "../../../data/api/types/vehiclesSchedule";
import {
  CarRoster,
  DayExplPlanRoster,
  DaySpecShiftRoster,
  LineTimetableRoster,
  WorkShiftRoster,
} from "../../reports/useTimetables_ByDate";
import { secondsToTime } from "./createRoster";
import {
  ElectroRoster,
  ElectroRosterCar,
  ElectroRosterDriver,
  ElectroRosterSpecialCar,
  ElectroRosterSpecislCarShift,
  ElectroRosterTimetableCar,
  ElectroRosterTimetableCarShift,
  ElectroRosterVehicle,
  ElectroSpecialRosterShift,
  ElectroTimetableRosterShift,
} from "./ElectroRoster";

export const emptyElectroRoster: ElectroRoster = {
  allShifts: [],
  rosterCars: [],
  unusedRosterDrivers: [],
  unusedRosterVehicles: [],
  allRosterVehicles: [],
  summary: {
    emptyShifts: 0,
    noVehicleShifts: 0,
  },
};

function processShift(
  shift: WorkShiftRoster,
  date: string,
  line: LineTimetableRoster,
  car: CarRoster,
  allowed_shifts: number[]
): ElectroTimetableRosterShift | null {
  if (allowed_shifts.includes(shift.shift_type)) {
    // console.log("Processing shift:", shift);
    const [start_days, start_time_str] = secondsToTime(shift.start_time);
    const start_date = moment(date, "YYYY-MM-DD").add(start_days, "days");
    const start_time = moment(
      `${start_date.format("YYYY-MM-DD")}T${start_time_str}`
    );
    const [end_days, end_time_str] = secondsToTime(
      shift.end_time_2 ? shift.end_time_2 : shift.end_time
    );
    const end_date = moment(date, "YYYY-MM-DD").add(end_days, "days");
    const end_time = moment(`${end_date.format("YYYY-MM-DD")}T${end_time_str}`);
    if (end_time.isBefore(start_time)) {
      end_time.add(1, "day");
    }

    return {
      type: "timetable",
      line_order: line.line_order,
      start_time,
      end_time,
      passenger_start: shift.passenger_start
        ? moment(date, "YYYY-MM-DD")
          .startOf("day")
          .add(shift.passenger_start, "seconds")
        : null,
      passenger_end: shift.passenger_end
        ? moment(date, "YYYY-MM-DD")
          .startOf("day")
          .add(shift.passenger_end, "seconds")
        : null,
      single_shift: !!shift.single_shift,
      work_time: end_time.diff(start_time, "minutes"),
      shift: {
        line: line.line,
        lineName: line.line_name,
        lineOrder: line.line_order,
        timetableId: line.timetable_id,
        timetableCode: line.code,
        car: car.car_no,
        original_car: car.car_no,
        places: shift.vehicletype?.places || null,
        workshift: {
          ...shift,
          number: shift.shift_type,
        },
      },
    };
  } else {
    return null;
  }
}

function getInitialLineShifts(
  autocolumn: number,
  date: string,
  expl_plan: readonly LineTimetableRoster[] | null | undefined
): readonly ElectroTimetableRosterShift[] {
  if (expl_plan && expl_plan.length > 0) {
    const shifts = expl_plan.flatMap((line) =>
      line.cars.flatMap<ElectroTimetableRosterShift>(
        (car) =>
          car.workshifts
            .filter((shift) => shift.autocolumn === autocolumn)
            .map((shift) => processShift(shift, date, line, car, [1, 2]))
            .filter((shift) => !!shift) as ElectroTimetableRosterShift[]
      )
    );

    const additionalShifts = expl_plan
      .flatMap((line) =>
        line.cars.flatMap<ElectroTimetableRosterShift>(
          (car) =>
            car.workshifts
              .filter((shift) => shift.autocolumn === autocolumn)
              .map((shift) => processShift(shift, date, line, car, [3]))
              .filter((shift) => !!shift) as ElectroTimetableRosterShift[]
        )
      )
      .map((shift) => ({
        ...shift,
        shift: {
          ...shift.shift,
          workshift: {
            ...shift.shift.workshift,
            number: 2,
            original_number: 3,
            shift_type: 2,
            original_shift_type: 3,
            car: shift.shift.car + 100,
          },
          car: shift.shift.car + 100,
        },
      }));

    const sortedShifts: ElectroTimetableRosterShift[] = (sortArray as any)(
      shifts.concat(additionalShifts),
      ["line_order", "shift.original_car", "shift.workshift.shift_type"],
      { in_place: true }
    );

    return sortedShifts;
  } else {
    return [];
  }
}

function getTimetableCars(
  shifts: readonly ElectroTimetableRosterShift[]
): readonly ElectroRosterTimetableCar[] {
  const cars: ElectroRosterTimetableCar[] = [];
  for (let i = 0; i < shifts.length; ++i) {
    if (
      i === 0 ||
      shifts[i].shift.line !== shifts[i - 1].shift.line ||
      shifts[i].shift.car !== shifts[i - 1].shift.car
    ) {
      let car: ElectroRosterTimetableCar;
      if (shifts[i].shift.workshift.number === 2) {
        car = {
          isTimetableCar: true,
          shift1: null,
          shift2: {
            isTimetableCar: true,
            shift: shifts[i],
            driver: null,
            vehicle: null,
          },
          line: shifts[i].shift.line,
          lineName: shifts[i].shift.lineName,
          lineOrder: shifts[i].shift.lineOrder,
          car: shifts[i].shift.car,
          original_car: shifts[i].shift.original_car,
          places: shifts[i].shift.places,
        };
      } else {
        car = {
          isTimetableCar: true,
          shift1: {
            isTimetableCar: true,
            shift: shifts[i],
            driver: null,
            vehicle: null,
          },
          shift2: null,
          line: shifts[i].shift.line,
          lineName: shifts[i].shift.lineName,
          lineOrder: shifts[i].shift.lineOrder,
          car: shifts[i].shift.car,
          original_car: shifts[i].shift.original_car,
          places: shifts[i].shift.places,
        };
      }
      cars.push(car);
    } else if (shifts[i].shift.workshift.number !== 1) {
      cars[cars.length - 1] = {
        ...cars[cars.length - 1],
        shift2: {
          isTimetableCar: true,
          shift: shifts[i],
          driver: null,
          vehicle: null,
        },
      };
    } else {
      console.error("Unexpected shift type 1:", shifts[i]);
    }
  }
  return cars;
}

function getInitialSpecialShifts(
  date: string,
  expl_plan: readonly DaySpecShiftRoster[] | null | undefined
): readonly ElectroSpecialRosterShift[] {
  if (expl_plan && expl_plan.length > 0) {
    return expl_plan.flatMap((shift) => {
      // const start_time = moment(date, "YYYY-MM-DD")
      //   .startOf("day")
      //   .add(shift.start_time, "seconds");
      const [start_days, start_time_str] = secondsToTime(shift.start_time);
      const start_date = moment(date, "YYYY-MM-DD").add(start_days, "days");
      const start_time = moment(
        `${start_date.format("YYYY-MM-DD")}T${start_time_str}`
      );
      // const end_time = moment(date, "YYYY-MM-DD")
      //   .startOf("day")
      //   .add(shift.end_time, "seconds");
      const [end_days, end_time_str] = secondsToTime(shift.end_time);
      const end_date = moment(date, "YYYY-MM-DD").add(end_days, "days");
      const end_time = moment(
        `${end_date.format("YYYY-MM-DD")}T${end_time_str}`
      );
      if (end_time.isBefore(start_time)) {
        end_time.add(1, "day");
      }

      return {
        type: "special",
        start_time,
        end_time,
        work_time: end_time.diff(start_time, "minutes"),
        shift: {
          workshift: shift,
          places: null,
        },
        places: null,
      };
    });
  } else {
    return [];
  }
}

function getSpecialCars(
  shifts: readonly ElectroSpecialRosterShift[]
): readonly ElectroRosterSpecialCar[] {
  return shifts.map((s) => {
    if (s.shift.workshift.shift_type === 2) {
      return {
        isTimetableCar: false,
        shift1: null,
        shift2: {
          isTimetableCar: false,
          shift: s,
          driver: null,
          vehicle: null,
        },
        line: s.shift.workshift.id,
        lineName: s.shift.workshift.description,
        lineOrder: null,
        car: null,
        original_car: null,
        places: null,
      };
    } else {
      return {
        isTimetableCar: false,
        shift1: {
          isTimetableCar: false,
          shift: s,
          driver: null,
          vehicle: null,
        },
        shift2: null,
        line: s.shift.workshift.id,
        lineName: s.shift.workshift.description,
        lineOrder: null,
        car: null,
        original_car: null,
        places: null,
      };
    }
  });
}

function getRosterDrivers(
  schedule: Schedule,
  // drivers: readonly Driver[],
  shifts: readonly (ElectroTimetableRosterShift | ElectroSpecialRosterShift)[],
  selectedDate: string,
  autocolumn: number
): ElectroRosterDriver[] {
  const allRosterDrivers: ElectroRosterDriver[] = [];

  for (const scheduleDriver of Object.values(schedule.drivers)) {
    const currentDay = scheduleDriver.driver_days[selectedDate];
    const yesterday =
      scheduleDriver.driver_days[
      moment(selectedDate, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("YYYY-MM-DD")
      ];

    if (
      currentDay &&
      !currentDay.expired_docs &&
      [/*1,*/ 3, 4, 21, 32, 28, 2, 11, 14, 23, 22, 29].includes(
        currentDay.state
      )
    ) {
      // const driver = drivers.find(
      //   (d) => d.sl_number === scheduleDriver.sl_number
      // );
      if (
        /* От тук се взима само името, махаме го засега за да тръгне
        driver &&
        */
        currentDay.autocolumn === autocolumn &&
        (!currentDay.work_depot_id ||
          currentDay.work_depot_id === schedule.db_schedule.depot_id)
      ) {
        const shift = shifts.filter(
          (s) =>
            currentDay &&
            currentDay.daytasks.find(
              (dt) => dt.shift_id === s.shift.workshift.id
            )
        );

        const old_daytasks: Writeable<ElectroRosterDriver["old_daytasks"]> = [];
        for (let i = 0; i < 7; ++i) {
          const oldDay = moment(selectedDate, "YYYY-MM-DD").subtract(
            1 + i,
            "days"
          );
          const day = scheduleDriver.driver_days[oldDay.format("YYYY-MM-DD")];

          if (day) {
            const filteredDaytasks = day.daytasks.filter(
              (dt) =>
                dt.linename &&
                dt.linename.trim() !== "" &&
                dt.start_time &&
                dt.end_time
            );

            const tasks =
              filteredDaytasks.length > 0
                ? filteredDaytasks
                : day.workshift.linename &&
                  day.workshift.linename.trim() !== "" &&
                  day.workshift.start_time &&
                  day.workshift.end_time
                  ? [day.workshift]
                  : [];

            if (tasks.length > 0) {
              old_daytasks.push({
                date: oldDay.format("DD.MM.YYYY"),
                total_workseconds: 0,
                tasks: tasks.map((t) => ({
                  line_id: t.line_id,
                  linename: t.linename as string,
                  car_no: t.car_no,
                  shift_type: t.shift_type,
                  start_time: t.start_time as string,
                  end_time: t.end_time as string,
                  work_seconds: t.work_seconds,
                })),
              });
            } else {
              old_daytasks.push({
                date: oldDay.format("DD.MM.YYYY"),
                total_workseconds: 0,
                tasks: [
                  {
                    line_id: undefined,
                    linename: day.state_name,
                    car_no: null,
                    shift_type: null,
                    start_time: selectedDate,
                    end_time: selectedDate,
                    work_seconds: 0,
                  },
                ],
              });
            }
          } else {
            old_daytasks.push({
              date: oldDay.format("L"),
              total_workseconds: 0,
              tasks: [],
            });
          }
        }

        const daytasks = currentDay.daytasks.map((dt) => ({
          ...dt,
          rosterShift:
            shift.find((s) => s.shift.workshift.id === dt.shift_id) || null,
        }));

        const dtShiftTypes: (1 | 2)[] = [];
        for (const dt of daytasks) {
          if (
            dt.rosterShift &&
            dt.rosterShift.type === "timetable" &&
            (dt.shift_type === 1 || dt.shift_type === 2) &&
            !dtShiftTypes.includes(dt.shift_type)
          ) {
            dtShiftTypes.push(dt.shift_type);
          }
        }

        allRosterDrivers.push({
          type: "driver",
          driver_id: scheduleDriver.sl_number,
          schedule_id: scheduleDriver.id,
          sl_number: scheduleDriver.sl_number,
          name: scheduleDriver.name /*|| driver?.name*/ || "Неизвестно",
          preferred_vehicle: scheduleDriver.attributes?.preferred_vehicle,
          preferred_workshift: scheduleDriver.attributes?.preferred_workshift,
          schedule_state: currentDay.state,
          schedule_state_name: currentDay.state_name,
          schedule_state_abbr: currentDay.state_short_name,
          shift_type:
            dtShiftTypes.length === 1
              ? dtShiftTypes[0]
              : [22, 29].includes(currentDay.state)
                ? 2
                : 1,
          daytasks,
          old_daytasks,
          total_workseconds: yesterday?.total_workseconds || 0,
          total_quota: yesterday?.total_quota || 0,
          intershift_times: currentDay?.intershift_times || [],
          color:
            scheduleDriver.attributes?.color_id === 1
              ? "red"
              : scheduleDriver.attributes?.color_id === 2
                ? "green"
                : null,
          roster_issues: currentDay.roster_issues,
          comment: currentDay.comment,
          skillVehicleTypes:
            scheduleDriver.attributes?.vehicletypes_skills?.flatMap(
              (s) => s.razpcad_vehicletype_ids
            ) || [],
          vehicletypes_skills:
            scheduleDriver.attributes?.vehicletypes_skills || [],
        });
      }
    }
  }

  return allRosterDrivers;
}

function fillElectroRosterCarShift(
  carShift:
    | Writeable<ElectroRosterTimetableCarShift>
    | Writeable<ElectroRosterSpecislCarShift>,
  allRosterDrivers: ElectroRosterDriver[],
  allRosterVehicles: ElectroRosterVehicle[]
) {
  const driverIdx = allRosterDrivers.findIndex((d) => {
    const dtIdx = d.daytasks.findIndex(
      (dt) => dt.shift_id && dt.shift_id === carShift.shift.shift.workshift.id
    );
    return dtIdx >= 0 && dtIdx < d.daytasks.length;
  });

  if (driverIdx >= 0 && driverIdx < allRosterDrivers.length) {
    if (carShift.driver) {
      console.warn("Duplicate driver for shift:", carShift);
    } else {
      carShift.driver = allRosterDrivers[driverIdx];

      const vehIdx = allRosterVehicles.findIndex((v) => {
        const dtIdx = carShift.driver?.daytasks.findIndex(
          (dt) => dt.veh1_id && dt.veh1_id === v.vehicle_id
        );
        return (
          dtIdx !== undefined &&
          dtIdx !== null &&
          dtIdx >= 0 &&
          dtIdx < (carShift.driver?.daytasks.length || 0)
        );
      });

      if (vehIdx >= 0 && vehIdx < allRosterVehicles.length) {
        if (carShift.vehicle) {
          console.warn("Duplicate vehicle for shift:", carShift);
        } else {
          carShift.vehicle = allRosterVehicles[vehIdx];
        }
      }
    }
  }
}

function fillElectroRosterCars(
  cars: readonly ElectroRosterCar[],
  allRosterDrivers: ElectroRosterDriver[],
  allRosterVehicles: ElectroRosterVehicle[],
  allShifts: readonly (
    | ElectroTimetableRosterShift
    | ElectroSpecialRosterShift
  )[]
): readonly [
  readonly ElectroRosterDriver[],
  readonly ElectroRosterVehicle[],
  readonly (ElectroTimetableRosterShift | ElectroSpecialRosterShift)[],
  readonly (ElectroTimetableRosterShift | ElectroSpecialRosterShift)[]
] {
  const usedRosterDrivers: Record<number, ElectroRosterDriver> = {};
  const usedRosterVehicles: Record<number, ElectroRosterVehicle> = {};
  const usedRosterShifts: Record<
    string,
    ElectroTimetableRosterShift | ElectroSpecialRosterShift
  > = {};
  const usedRosterShiftsWithVehicle: Record<
    string,
    ElectroTimetableRosterShift | ElectroSpecialRosterShift
  > = {};

  for (const car of cars) {
    if (car.shift1) {
      fillElectroRosterCarShift(
        car.shift1,
        allRosterDrivers,
        allRosterVehicles
      );
      if (car.shift1.driver) {
        usedRosterDrivers[car.shift1.driver.sl_number] = car.shift1.driver;
        usedRosterShifts[car.shift1.shift.shift.workshift.id] =
          car.shift1.shift;
      }
      if (car.shift1.vehicle) {
        usedRosterVehicles[car.shift1.vehicle.vehicle_id] = car.shift1.vehicle;
        usedRosterShifts[car.shift1.shift.shift.workshift.id] =
          car.shift1.shift;
        usedRosterShiftsWithVehicle[car.shift1.shift.shift.workshift.id] =
          car.shift1.shift;
      }
    }
    if (car.shift2) {
      fillElectroRosterCarShift(
        car.shift2,
        allRosterDrivers,
        allRosterVehicles
      );
      if (car.shift2.driver) {
        usedRosterDrivers[car.shift2.driver.sl_number] = car.shift2.driver;
        usedRosterShifts[car.shift2.shift.shift.workshift.id] =
          car.shift2.shift;
      }
      if (car.shift2.vehicle) {
        usedRosterVehicles[car.shift2.vehicle.vehicle_id] = car.shift2.vehicle;
        usedRosterShifts[car.shift2.shift.shift.workshift.id] =
          car.shift2.shift;
        usedRosterShiftsWithVehicle[car.shift2.shift.shift.workshift.id] =
          car.shift2.shift;
      }
    }
  }

  const unusedRosterDrivers: ElectroRosterDriver[] = [];
  const unusedRosterVehicles: ElectroRosterVehicle[] = [];
  const unusedRosterShifts: (
    | ElectroTimetableRosterShift
    | ElectroSpecialRosterShift
  )[] = [];
  const noVehicleRosterShifts: (
    | ElectroTimetableRosterShift
    | ElectroSpecialRosterShift
  )[] = [];

  for (const driver of allRosterDrivers) {
    if (!Object.hasOwn(usedRosterDrivers, driver.sl_number)) {
      unusedRosterDrivers.push(driver);
    }
  }
  for (const vehicle of allRosterVehicles) {
    if (!Object.hasOwn(usedRosterVehicles, vehicle.vehicle_id)) {
      unusedRosterVehicles.push(vehicle);
    }
  }
  for (const shift of allShifts) {
    if (!Object.hasOwn(usedRosterShifts, shift.shift.workshift.id)) {
      unusedRosterShifts.push(shift);
    }
  }
  for (const shift of allShifts) {
    if (!Object.hasOwn(usedRosterShiftsWithVehicle, shift.shift.workshift.id)) {
      noVehicleRosterShifts.push(shift);
    }
  }

  return [
    unusedRosterDrivers,
    unusedRosterVehicles,
    unusedRosterShifts,
    noVehicleRosterShifts,
  ];
}

export function createElectroRoster(
  autocolumn: number,
  date: string,
  // driverList: DriverList,
  vehiclesSchedule: Record<number, ScheduleVehicle>,
  schedule: Schedule,
  explPlan: DayExplPlanRoster,
  vehicleTypes: readonly {
    id: number;
    description: string;
    koef: number;
    places: number;
    energy_meter: boolean | null | undefined;
  }[]
): ElectroRoster {
  console.time("Create electro roster");
  try {
    const vehicles = sortArray(
      Object.values(vehiclesSchedule).filter(
        (v) =>
          v.days[date] &&
          (!v.days[date].autocolumn ||
            v.days[date].autocolumn === autocolumn) &&
          (!v.days[date]?.state ||
            v.days[date]?.state?.category === 1 ||
            v.days[date]?.state?.category === 2)
      ),
      ["vehicletype_id", "vehicle_id"]
    );
    // const drivers = driverList.drivers.filter(
    //   (driver) => driver.avtocolona === autocolumn
    // );
    // const drivers = driverList.drivers;

    const allTimetableShifts = getInitialLineShifts(
      autocolumn,
      date,
      explPlan.expl_plan
    );
    const allTimetableCars = getTimetableCars(allTimetableShifts);
    const allSpecialShifts = getInitialSpecialShifts(
      date,
      explPlan.spec_shifts
    );
    const allSpecialCars = getSpecialCars(allSpecialShifts);
    const allShifts = [...allTimetableShifts, ...allSpecialShifts];
    const allCars: ElectroRosterCar[] = [
      ...allTimetableCars,
      ...allSpecialCars,
    ];

    const allRosterDrivers = getRosterDrivers(
      schedule,
      // drivers,
      allShifts,
      date,
      autocolumn
    );

    const allRosterVehicles: ElectroRosterVehicle[] = vehicles.map((v) => {
      const regularDrivers = allRosterDrivers
        .filter(
          (d) => !!d.preferred_vehicle && d.preferred_vehicle === v.vehicle_id
        )
        .map((d) => ({ id: d.driver_id, name: d.name }));

      const vt = vehicleTypes.find((vt) => vt.id === v.vehicletype_id);
      return {
        vehicle_id: v.vehicle_id,
        vehicletype_id: v.vehicletype_id,
        vehicletype_name: vt?.description || "Неизвестна",
        includeEnergy: vt?.energy_meter,
        vehicle: v,
        composition: v.days[date]?.composition || null,
        places: v.days[date]?.places || 0,
        regularDrivers: regularDrivers,
      };
    });

    const [
      unusedRosterDrivers,
      unusedRosterVehicles,
      unusedRosterShifts,
      noVehicleRosterShifts,
    ] = fillElectroRosterCars(
      allCars,
      allRosterDrivers,
      allRosterVehicles,
      allShifts
    );

    return {
      allShifts: allShifts,
      rosterCars: allCars,
      unusedRosterDrivers: unusedRosterDrivers,
      unusedRosterVehicles: unusedRosterVehicles,
      allRosterVehicles: allRosterVehicles,
      summary: {
        emptyShifts: unusedRosterShifts.length,
        noVehicleShifts: noVehicleRosterShifts.length,
      },
    };
  } finally {
    console.timeEnd("Create electro roster");
  }
}
