import { blue, orange, red, yellow } from "@mui/material/colors";
import {
  RosterShift,
  SpecialRosterShift,
  TimetableRosterShift,
} from "./types/Roster";
import {
  LATE_CAR_SECONDS,
  NIGHTCAR_SECONDS,
} from "../reports/useTimetables_ByDate";
import moment from "moment";
import { Transport } from "../../data/api/types/schedule";
import {
  ElectroSpecialRosterShift,
  ElectroTimetableRosterShift,
} from "./types/ElectroRoster";

export function isLateCar(
  shift: RosterShift | ElectroTimetableRosterShift | ElectroSpecialRosterShift,
  selectedDate: string
) {
  //console.log("isLateCar");
  if (shift.type === "timetable") {
    if (shift.shift.workshift.shift_type === 2) {
      return shift.end_time.isSameOrAfter(
        moment(selectedDate, "YYYY-MM-DD")
          .startOf("day")
          .add(NIGHTCAR_SECONDS, "seconds")
      );
    } else {
      return shift.end_time.isSameOrAfter(
        moment(selectedDate, "YYYY-MM-DD")
          .startOf("day")
          .add(LATE_CAR_SECONDS, "seconds")
      );
    }
  } else {
    return false;
  }
}

export function getShiftColor(shift: RosterShift, selectedDate: string) {
  if (shift.type === "timetable") {
    if (isLateCar(shift, selectedDate)) {
      return shift.shift.workshift.shift_type === 2 ? red[200] : blue[300];
    } else {
      return shift.shift.workshift.shift_type === 2 ? orange[200] : blue[100];
    }
  } else {
    return yellow[200];
  }
}

export function getShiftText(shift: RosterShift, short = false): string {
  const shortName =
    shift.type === "timetable"
      ? `${shift.shift.lineName}/${shift.shift.car}${short && shift.single_shift ? "(ЕР)" : ""
      }`
      : `${(shift.shift.workshift.description || "")
        .substring(0, 4)
        .toLocaleUpperCase()}...`;

  if (short) {
    return shortName;
  } else {
    const nameSuffix = `/${shift.type === "timetable"
        ? (shift.shift.workshift.number || 1).toString() +
        (shift.single_shift ? "(ЕР)" : "")
        : shift.shift.workshift.shift_type.toString()
      }`;
    return shortName.concat(nameSuffix);
  }
}

function getTimetableShiftTitle(
  shift: TimetableRosterShift | ElectroTimetableRosterShift,
  selectedDate: string,
  onAnotherVehicle: number | null | undefined,
  selectedTransport: Transport
): readonly string[] {
  return [
    selectedTransport !== "A" && shift.shift.places && shift.shift.places > 0
      ? [`Места: ${shift.shift.places}`]
      : [],
    `Линия: ${shift.shift.lineName}/${shift.shift.car}`,
    `Смяна: ${(shift.shift.workshift as any).original_number ||
    shift.shift.workshift.number ||
    1
    }`,
    onAnotherVehicle ? `Работи с ПС: ${onAnotherVehicle}` : [],
    shift.single_shift ? "Едносменна разпокъсана" : [],
    `Марка: ${shift.shift.workshift.vehicletype?.description || "Неизвестна"}`,
    `Работно време: ${shift.start_time.format(
      "HH:mm"
    )} - ${shift.end_time.format("HH:mm")}`,
    `Заработка: ${Math.floor(shift.work_time / 60)
      .toString()
      .padStart(2, "0")}:${(shift.work_time % 60).toString().padStart(2, "0")}`,
    isLateCar(shift, selectedDate) ? "Късна кола" : "Ранна кола",
  ]
    .flat()
    .filter((l) => l && l.trim() !== "");
}

function getSpecialShiftTitle(
  shift: SpecialRosterShift | ElectroSpecialRosterShift,
  selectedDate: string,
  onAnotherVehicle: number | null | undefined
): readonly string[] {
  return [
    `Смяна: ${shift.shift.workshift.description}`,
    onAnotherVehicle ? `Работи с ПС: ${onAnotherVehicle}` : [],
    `Работно време: ${shift.start_time.format(
      "HH:mm"
    )} - ${shift.end_time.format("HH:mm")}`,
    `Заработка: ${Math.floor(shift.work_time / 60)
      .toString()
      .padStart(2, "0")}:${(shift.work_time % 60).toString().padStart(2, "0")}`,
    isLateCar(shift, selectedDate) ? "Късна кола" : "Ранна кола",
  ]
    .flat()
    .filter((l) => l && l.trim() !== "");
}

export function getShiftTitle(
  shift1:
    | RosterShift
    | ElectroTimetableRosterShift
    | ElectroSpecialRosterShift
    | null,
  shift2:
    | RosterShift
    | ElectroTimetableRosterShift
    | ElectroSpecialRosterShift
    | null,
  selectedDate: string,
  onAnotherVehicle: number | null | undefined,
  selectedTransport: Transport
): string {
  if (shift1 && shift2) {
    return (
      shift1.type === "timetable" && shift2.type === "timetable"
        ? getTimetableShiftTitle(
          shift1,
          selectedDate,
          onAnotherVehicle,
          selectedTransport
        ).concat(
          ["--------------------"].concat(
            getTimetableShiftTitle(
              shift2,
              selectedDate,
              onAnotherVehicle,
              selectedTransport
            )
          )
        )
        : shift1.type === "special" && shift2.type === "special"
          ? getSpecialShiftTitle(shift1, selectedDate, onAnotherVehicle).concat(
            ["--------------------"].concat(
              getSpecialShiftTitle(shift2, selectedDate, onAnotherVehicle)
            )
          )
          : []
    ).join("\n");
  } else if (shift1) {
    return (
      shift1.type === "timetable"
        ? getTimetableShiftTitle(
          shift1,
          selectedDate,
          onAnotherVehicle,
          selectedTransport
        )
        : getSpecialShiftTitle(shift1, selectedDate, onAnotherVehicle)
    ).join("\n");
  } else if (shift2) {
    return (
      shift2.type === "timetable"
        ? getTimetableShiftTitle(
          shift2,
          selectedDate,
          onAnotherVehicle,
          selectedTransport
        )
        : getSpecialShiftTitle(shift2, selectedDate, onAnotherVehicle)
    ).join("\n");
  } else {
    return "";
  }
}
